<template>
  <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
</template>

<script>
import SmReport from '@/components/views/SmReport.vue';

export default {
  name: 'TelemarketingPerformingReport',

  components: {
    SmReport,
  },

  data() {
    return {
      reportName: 'TelemarketingPerformingReport',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Отчет по выполнению заданий по ТМ',
        },
      ],
    };
  },
};
</script>
